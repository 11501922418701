import { FolderOpenOutlined } from "@ant-design/icons";
import { ApiError, Position, useCompany } from "@inspecto/common";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Icon } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../api";
import {
  GoogleMapEmbedModal,
  ProtocolPosition,
  StatusIcon,
} from "../../components";
import {
  useFetch,
  useVehicleFieldsPresets,
  useVehicleGroups,
  useVehicleTypes,
} from "../../hooks";
import {
  labelTranslationKeyByCustomVehicleFieldStatus,
  RetrieveVehicle,
  RetrieveVehicleCustomVehicleField,
} from "../../models";
import { getResultingVehicleFieldsStatus } from "../../utils";
import { BackOfficeLayout } from "../BackOfficeLayout";
import { filesFolderIconColor } from "./common";
import { UpdateVehicleFormModal, VehicleCardTabs } from "./components";
import { DocumentsDrawer } from "./components/DocumentsDrawer";

function InfoCardRow(props: PropsWithChildren<{}>): JSX.Element {
  return (
    <Card>
      <Row gutter={[24, 12]} align="middle">
        {props.children}
      </Row>
    </Card>
  );
}

function InfoCardCol(props: {
  label: string;
  content: ReactNode;
}): JSX.Element {
  return (
    <Col xs={24}>
      <Typography.Text type="secondary">{props.label}</Typography.Text>
      {props.content}
    </Col>
  );
}

type VehicleNotFound = null;
type VehicleNotYetFetched = undefined;

export function VehicleCardView() {
  const { t } = useTranslation("backoffice");
  const company = useCompany();
  const [positionModalValue, setPositionModalValue] = useState(
    null as Position | null
  );
  const [isVehicleFormModalVisible, setIsVehicleFormModalVisible] =
    useState<boolean>(false);
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [isVehicleLoading, setIsVehicleLoading] = useState(true);
  const [isDocumentsDrawerVisible, setIsDocumentsDrawerVisible] =
    useState(false);
  const [vehicleCustomVehicleFields, setVehicleCustomVehicleFields] = useState<
    Record<string, RetrieveVehicleCustomVehicleField>
  >({});
  const [vehicle, setVehicle] = useState<
    RetrieveVehicle | VehicleNotFound | VehicleNotYetFetched
  >(undefined);
  const { isLoadingVehicleGroups, vehicleGroupOptions } = useVehicleGroups();
  const { isLoadingVehicleFieldsPresets, vehicleFieldsPresetOptions } =
    useVehicleFieldsPresets();
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();

  const [documentsCountState, fetchDocumentsCount] = useFetch(
    useCallback(
      () => backOfficeApi.documents.getVehicleDocumentsCount(vehicleId),
      [vehicleId]
    )
  );

  const loadVehicle = useCallback(async () => {
    setIsVehicleLoading(true);
    try {
      const vehicle = await backOfficeApi.getVehicle(vehicleId);
      setVehicle(vehicle);
    } catch (e) {
      if (e instanceof ApiError && e.statusCode === 404) {
        setVehicle(null);
      } else {
        throw e;
      }
    } finally {
      setIsVehicleLoading(false);
    }
  }, [vehicleId]);

  const vehicleStatus = useMemo(
    () =>
      getResultingVehicleFieldsStatus(
        Object.values(vehicleCustomVehicleFields).map(
          (customVehicleField) => customVehicleField.valueObject.status
        )
      ),
    [vehicleCustomVehicleFields]
  );

  const loadVehicleFieldValues = useCallback(async () => {
    const vehicleCustomFields =
      await backOfficeApi.getVehicleCustomVehicleFields(vehicleId);

    const customVehicleFieldValuesByFieldId = vehicleCustomFields.reduce<
      Record<string, RetrieveVehicleCustomVehicleField>
    >((acc, curr) => {
      return {
        ...acc,
        [curr.fieldObject.id]: curr,
      };
    }, {});
    setVehicleCustomVehicleFields(customVehicleFieldValuesByFieldId);
  }, [vehicleId]);

  useEffect(() => {
    loadVehicle();
    loadVehicleFieldValues();
  }, [loadVehicle, loadVehicleFieldValues]);

  const currentVehicleType = vehicleTypeOptions.find(
    (option) => option.value === vehicle?.vehicleType
  );

  const currentVehicleGroups = vehicleGroupOptions.filter((option) =>
    vehicle?.vehicleGroups.includes(option.value)
  );
  const currentVehicleFieldsPreset = vehicleFieldsPresetOptions.find(
    (option) => vehicle?.vehicleFieldsPreset === option.value
  );

  const onDocumentsChange = useCallback(
    () => fetchDocumentsCount(),
    [fetchDocumentsCount]
  );
  return (
    <BackOfficeLayout
      contentMinWidth={0}
      breadcrumbs={[
        {
          label: t("vehiclesView.pageTitle"),
          url: urls.backOffice.vehicles(),
        },
      ]}
      pageTitle={t("vehicleCard")}
    >
      <BackOfficeLayout.Content>
        {isVehicleLoading ||
        isLoadingVehicleGroups ||
        isLoadingVehicleFieldsPresets ||
        isLoadingVehicleTypes ||
        typeof vehicle === "undefined" ? (
          <Skeleton />
        ) : vehicle === null ? (
          <Alert
            type="error"
            message={t("vehicleView.doesNotExistOrNotAllowed")}
          />
        ) : (
          <>
            <Row gutter={[24, 12]} style={{ marginBottom: 40 }} align="stretch">
              <Col xs={24} lg={12} xxl={10}>
                <InfoCardRow>
                  <Col xs={24}>
                    <Space
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Typography.Title level={5}>
                        {t("vehicleView.basicInformation")}
                      </Typography.Title>
                      <Button
                        shape="round"
                        size="small"
                        onClick={() => setIsVehicleFormModalVisible(true)}
                        title={`${t("vehicleView.customFields.edit")} - ${t(
                          "vehicleView.basicInformation"
                        )}`}
                      >
                        <Space>
                          <Icon icon="edit" />
                          {t("vehicleView.customFields.edit")}
                        </Space>
                      </Button>
                    </Space>
                  </Col>
                  <InfoCardCol
                    label={`${t("vehicleType")}: `}
                    content={
                      <Typography.Text strong>
                        {currentVehicleType ? currentVehicleType.label : "-"}
                      </Typography.Text>
                    }
                  />
                  <InfoCardCol
                    label={`${t("plateNumber")}: `}
                    content={
                      <Typography.Text strong>
                        {vehicle.plateNumber}
                      </Typography.Text>
                    }
                  />
                  {company.allowManagingCompanyDepartments && (
                    <InfoCardCol
                      label={`${t(
                        "settings.companyDepartments.singleItemLabel"
                      )}: `}
                      content={<Tag>{vehicle.companyDepartment.name}</Tag>}
                    />
                  )}
                  <InfoCardCol
                    label={`${t("vehicleGroups")}: `}
                    content={
                      currentVehicleGroups.length
                        ? currentVehicleGroups.map((vehicleGroup) => (
                            <Tag>
                              <a
                                href={urls.backOffice.settings.editVehicleGroup(
                                  vehicleGroup.value
                                )}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {vehicleGroup.label}
                              </a>
                            </Tag>
                          ))
                        : "-"
                    }
                  />
                  <InfoCardCol
                    label={`${t("vehicleView.vehicleFieldsPreset")}: `}
                    content={
                      !!currentVehicleFieldsPreset ? (
                        <Tag>
                          <a
                            href={urls.backOffice.settings.editVehicleFieldsPreset(
                              currentVehicleFieldsPreset.value
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {currentVehicleFieldsPreset.label}
                          </a>
                        </Tag>
                      ) : (
                        "-"
                      )
                    }
                  />
                </InfoCardRow>
              </Col>
              <Col xs={24} lg={12} xxl={9}>
                <Space direction="vertical" style={{ display: "flex" }}>
                  <InfoCardRow>
                    <Col xs={24}>
                      <Space size="small">
                        <Typography.Text type="secondary">
                          {t("status")}
                          {":"}
                        </Typography.Text>
                        <StatusIcon status={vehicleStatus} />
                        <Typography.Text
                          strong
                          data-testid={`vehicle-status-${vehicleStatus}`}
                        >
                          {t(
                            `fleetState.statuses.${labelTranslationKeyByCustomVehicleFieldStatus[vehicleStatus]}`
                          )}
                        </Typography.Text>
                      </Space>
                    </Col>
                    <Col xs={24}>
                      <div style={{ paddingBottom: 5 }}>
                        <Typography.Text type="secondary">
                          {t("vehicleView.lastProtocolCreationLocation")}
                        </Typography.Text>
                      </div>
                      <ProtocolPosition
                        displayDate
                        positionObject={vehicle.positionObject || null}
                        onPositionClick={(position) =>
                          setPositionModalValue(position)
                        }
                      />
                    </Col>
                  </InfoCardRow>
                  {company.allowStoringDocumentsOnVehicles && (
                    <InfoCardRow>
                      <Col xs={24}>
                        <Space
                          size="small"
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          <Space align="center">
                            <FolderOpenOutlined
                              style={{
                                fontSize: 20,
                                color: filesFolderIconColor,
                              }}
                            />
                            <Typography.Title level={5} style={{ margin: 0 }}>
                              {t("vehicleView.filesFolder.title")}
                            </Typography.Title>
                            {documentsCountState.state === "dataFetched" && (
                              <Badge
                                count={documentsCountState.data.count}
                                showZero
                                color="cyan"
                              />
                            )}
                          </Space>

                          <Button
                            shape="round"
                            size="small"
                            onClick={() => setIsDocumentsDrawerVisible(true)}
                          >
                            <Space>
                              <Icon icon="arrowRight" />
                              {t("vehicleView.filesFolder.open")}
                            </Space>
                          </Button>
                        </Space>
                        {t("vehicleView.filesFolder.description")}
                      </Col>
                    </InfoCardRow>
                  )}
                </Space>
              </Col>
            </Row>

            <VehicleCardTabs
              vehicleId={vehicleId}
              vehiclePlateNumber={vehicle.plateNumber}
              vehicleTypeId={vehicle.vehicleType}
              loadVehicleFieldValues={loadVehicleFieldValues}
              vehicleCustomVehicleFields={vehicleCustomVehicleFields}
            />

            {positionModalValue && (
              <GoogleMapEmbedModal
                position={positionModalValue}
                onClose={() => setPositionModalValue(null)}
              />
            )}
            {isVehicleFormModalVisible && (
              <UpdateVehicleFormModal
                currentVehicleValue={vehicle}
                reloadVehicleAndFieldValues={() => {
                  loadVehicle();
                  loadVehicleFieldValues();
                }}
                vehicleGroupsOptions={vehicleGroupOptions}
                vehicleFieldsPresetOptions={vehicleFieldsPresetOptions}
                vehicleTypeOptions={vehicleTypeOptions}
                closeModal={() => setIsVehicleFormModalVisible(false)}
              />
            )}
            {isDocumentsDrawerVisible && (
              <DocumentsDrawer
                onClose={() => setIsDocumentsDrawerVisible(false)}
                onDocumentsChange={onDocumentsChange}
                vehicleId={vehicleId}
                vehiclePlateNumber={vehicle.plateNumber}
              />
            )}
          </>
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
