import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Form, Space, TableColumnType, Tag } from "antd";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RemoveButton } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { CommonViewState } from "../../../contexts";
import { ListLinkType } from "../../../models/LinkType";
import { BackOfficeListViewLayout } from "../../BackOfficeListViewLayout";

export function LinkTypesSettingsListView() {
  const { t } = useTranslation("backoffice");
  const [filtersForm] = Form.useForm();
  const history = useHistory();

  const dataGetter = useCallback(
    ({
      tableParams: { pageNumber },
    }: CommonViewState["vehicleFieldsPresetsView"]) =>
      backOfficeApi.linkTypes.getList(pageNumber),
    []
  );

  const columns: TableColumnType<ListLinkType>[] = useMemo(
    () => [
      {
        title: t("settings.linkTypes.fields.leftSideLabel"),
        dataIndex: "leftName",
        width: 300,
      },
      {
        title: t("settings.linkTypes.fields.leftSideVehicleTypes"),
        dataIndex: "leftVehicleTypeNames",
        width: 300,
        render: (value: ListLinkType["leftVehicleTypeNames"]) => (
          <>
            {value.map((v) => (
              <Tag>{v}</Tag>
            ))}
          </>
        ),
      },
      {
        title: t("settings.linkTypes.fields.rightSideLabel"),
        dataIndex: "rightName",
        width: 300,
      },
      {
        title: t("settings.linkTypes.fields.rightSideVehicleTypes"),
        dataIndex: "rightVehicleTypeNames",
        width: 300,
        render: (value: ListLinkType["rightVehicleTypeNames"]) => (
          <>
            {value.map((v) => (
              <Tag>{v}</Tag>
            ))}
          </>
        ),
      },
      {
        key: "action",
        width: 250,
        align: "right",
        render: (value, linkType) => (
          <Space size="small">
            <Button
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                history.push(
                  urls.backOffice.settings.linkTypes.edit(linkType.id)
                );
              }}
              icon={<ArrowRightOutlined />}
            >
              {t("tableActions.edit")}
            </Button>
            <RemoveButton
              confirmationPhrase={linkType.leftName}
              onRemoveConfirm={async () => {
                await backOfficeApi.linkTypes.delete(value.id);
                filtersForm.submit();
              }}
            />
          </Space>
        ),
      },
    ],
    [filtersForm, history, t]
  );

  return (
    <BackOfficeListViewLayout
      dataGetter={dataGetter}
      columns={columns}
      commonStateViewName="linkTypesSettings"
      filtersForm={filtersForm}
      pageTitle={t("settings.linkTypes.listLabel")}
      breadcrumbs={[{ label: t("settings.label") }]}
      createPageUrl={urls.backOffice.settings.linkTypes.create()}
    />
  );
}
