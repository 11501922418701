import {
  CustomVehicleFieldType,
  DamagesCustomVehicleFieldType,
} from "src/company";
import { CreateChoiceFieldChoice } from "src/components";
import { IconType } from "src/components/Icon";
import { TranslatedField } from "src/locales";
import { StorageUploadedImage } from "src/storage";

import { VehicleType } from "./VehicleObject";

export interface ListTemplateTag {
  id: string;
  label: string;
  order: number;
}

export interface ListTemplate {
  id: string;
  label: string;
  isActive: boolean;
  templateTags: ListTemplateTag[];
  vehicleTypes: {
    id: string;
    label: string;
  }[];
}

export interface TemplateCustomVehicleFields {
  id: string;
  label: string;
  customVehicleFields: { id: string; type: CustomVehicleFieldType }[];
}

export interface QuestionCommon {
  readonly frontendId: string;
  readonly id: string | null;
  label: TranslatedField;
  description: TranslatedField;
  descriptionImage: StorageUploadedImage | null;
}

export interface TextQuestion extends QuestionCommon {
  questionType: "text";
  isRequired: boolean;
}

export interface NumberQuestion extends QuestionCommon {
  questionType: "number";
  isRequired: boolean;
}

export interface DateQuestion extends QuestionCommon {
  questionType: "date";
  isRequired: boolean;
}

export interface DateMonthQuestion extends QuestionCommon {
  questionType: "date-month";
  isRequired: boolean;
}

export interface CustomFieldUpdateQuestion extends QuestionCommon {
  questionType: "custom-field-update";
  isRequired: boolean;
  customVehicleFieldId: string;
  isCustomVehicleFieldVerification: boolean;
}

export interface LinkingQuestion extends QuestionCommon {
  questionType: "linking";
  isRequired: boolean;
  linkTypeId: string;
}

export interface PhotoActionQuestion extends QuestionCommon {
  questionType: "photo-action";
  isRequired: boolean;
}

export interface Choice extends Omit<CreateChoiceFieldChoice, "id"> {
  id: string | null;
}

export interface ChoiceQuestion extends QuestionCommon {
  questionType: "choice";
  choices: Choice[];
}

export interface MultiChoiceQuestion extends QuestionCommon {
  questionType: "multi-choice";
  choices: Choice[];
}

export type RegularQuestion =
  | TextQuestion
  | NumberQuestion
  | DateQuestion
  | DateMonthQuestion
  | PhotoActionQuestion
  | MultiChoiceQuestion
  | ChoiceQuestion;

export type Question =
  | RegularQuestion
  | CustomFieldUpdateQuestion
  | LinkingQuestion;

export function isCustomFieldUpdateQuestion(
  question: Question
): question is CustomFieldUpdateQuestion {
  return question.questionType === "custom-field-update";
}

export enum SignatureType {
  SIGNATURE_ONLY = "signature_only",
  SIGNER_ONLY = "signer_only",
  SIGNATURE_AND_SIGNER = "signature_and_signer",
}

export function isSignatureWithSigner(type: SignatureType) {
  return [
    SignatureType.SIGNER_ONLY,
    SignatureType.SIGNATURE_AND_SIGNER,
  ].includes(type);
}

export function isSignatureWithSignature(type: SignatureType) {
  return [
    SignatureType.SIGNATURE_ONLY,
    SignatureType.SIGNATURE_AND_SIGNER,
  ].includes(type);
}

export interface PageCommon {
  readonly frontendId: string;
  readonly id: string | null;
}

export interface Page extends PageCommon {
  questions: Question[];
  title: TranslatedField;
}

export interface SignaturePage extends PageCommon {
  type: SignatureType;
  label: TranslatedField;
  signerRoles: string[];
  isRequired: boolean;
}

export interface Template {
  readonly frontendId: string;
  id: string | null; // Make this readonly as part of IN-258
  isActive: boolean;
  label: TranslatedField;
  pages: Page[];
  signaturePages: SignaturePage[];
  vehicleType: VehicleType["id"][];
  vehicleGroups: string[];
  templateTags: string[];
  userRoles: string[];
  imageQuality: "highQuality" | "standard";
}

export interface UpdateTemplate {
  isActive: boolean;
}

export function getIconName(
  questionType: Question["questionType"] | DamagesCustomVehicleFieldType
): IconType {
  switch (questionType) {
    case "text":
      return "font";
    case "number":
      return "calculator";
    case "date":
      return "calendar";
    case "date-month":
      return "calendarDays";
    case "photo-action":
      return "camera";
    case "choice":
      return "tasks";
    case "multi-choice":
      return "tasks";
    case "damages":
      return "bolt";
    case "custom-field-update":
      return "carSide";
    case "linking":
      return "link"; // TODO: Verify in IN-938
  }
}
