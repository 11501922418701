import { Form, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { MultiLanguageTextInput } from "src/company";

import { DefaultLanguageRequiredFormItem } from "../../../components";
import { CreateLinkType } from "../../../models/LinkType";

interface Props {
  linkSide: "A" | "B";
  linkSideImage: string;
  linkSideLabelName: keyof CreateLinkType;
  linkSideVehicleTypesName: keyof CreateLinkType;
  vehicleTypeOptions: { label: string; value: string }[];
}

const Section = styled.div`
  border-radius: 6px;
  padding: 17px;
  max-width: 700px;
  margin-bottom: 18px;
  border: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
`;

const LetterImg = styled.img`
  width: 50px;
`;
export function LinkField(props: Props) {
  const { t } = useTranslation("backoffice");

  return (
    <Section>
      <Typography.Title level={4}>
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          {t("settings.linkTypes.fields.linkSide", { side: props.linkSide })}
          <LetterImg src={props.linkSideImage} />
        </Space>
      </Typography.Title>

      <DefaultLanguageRequiredFormItem
        label={t("settings.linkTypes.fields.label")}
        name={props.linkSideLabelName}
      >
        <MultiLanguageTextInput />
      </DefaultLanguageRequiredFormItem>

      <Form.Item
        label={t("settings.linkTypes.fields.vehicleTypes")}
        name={props.linkSideVehicleTypesName}
        rules={[{ required: true }]}
        style={{ marginBottom: 10 }}
      >
        <Select
          showArrow
          filterOption={false}
          showSearch={false}
          mode="multiple"
          style={{ width: 350 }}
          options={props.vehicleTypeOptions}
        />
      </Form.Item>
    </Section>
  );
}
