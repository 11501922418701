import { Form, Input } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MultiLanguageTextarea } from "src/company";
import {
  SingleImageImmediateUploader,
  SwitchInput,
  ModalForm,
  useImmediateImageUploadersContext,
} from "src/components";
import { createGlobalErrorsError } from "src/utils";

import {
  DefaultLanguageRequiredFormItem,
  DraggableChoicesListFormItem,
  QuestionTypeWithAvatar,
} from "../../../components";
import {
  CustomFieldUpdateQuestion,
  Question,
  RegularQuestion,
} from "../../../models";
import { useTemplateContext } from "../TemplateEditorView";

export interface QuestionEditModalProps {
  question: Question;
  closeModal: () => void;
  saveQuestion: (question: Question) => void;
  onChangeCustomVehicleFieldClick: (
    question: CustomFieldUpdateQuestion
  ) => void;
  onChangeQuestionTypeClick: (question: RegularQuestion) => void;
}

export function QuestionEditModal(props: QuestionEditModalProps) {
  const { t } = useTranslation("backoffice");
  const [form] = Form.useForm();

  const { message: imageUploadersMessage } =
    useImmediateImageUploadersContext();
  const { setOnChoicesReorderCallback, customVehicleFields, linkTypes } =
    useTemplateContext();

  const questionLabelByType: Record<Question["questionType"], string> = useMemo(
    () => ({
      text: t("builder.fieldType.text"),
      number: t("builder.fieldType.number"),
      date: t("builder.fieldType.date"),
      "date-month": t("builder.fieldType.dateMonth"),
      choice: t("builder.fieldType.choice"),
      "multi-choice": t("builder.fieldType.multichoice"),
      "photo-action": t("builder.fieldType.photoAction"),
      damages: t("builder.fieldType.damages"),
      "custom-field-update": t("builder.fieldType.customFieldUpdate"),
      linking: t("builder.fieldType.linking"),
    }),
    [t]
  );

  const reorderChoices = useMemo(
    () => DraggableChoicesListFormItem.getOnChoicesReorderHandler(form),
    [form]
  );

  useEffect(() => {
    setOnChoicesReorderCallback(reorderChoices);

    return () => {
      setOnChoicesReorderCallback(null);
    };
  }, [setOnChoicesReorderCallback, reorderChoices]);

  const questionType = props.question.questionType;
  const customVehicleFieldId =
    props.question.questionType === "custom-field-update"
      ? props.question.customVehicleFieldId
      : null;
  const customVehicleField = customVehicleFields.find(
    (field) => field.id === customVehicleFieldId
  );
  const linkTypeId =
    props.question.questionType === "linking"
      ? props.question.linkTypeId
      : null;
  const linkType = linkTypes.find((linkType) => linkType.id === linkTypeId);

  return (
    <>
      <ModalForm<Question>
        disableMotion
        open
        width={775} // In order to support up to 7 languages in tabs
        form={form}
        saveCallback={async (question: Question) => {
          if (imageUploadersMessage) {
            throw createGlobalErrorsError([imageUploadersMessage]);
          }
          await props.saveQuestion(question);
        }}
        closeModal={props.closeModal}
        formTitle={
          t("builder.createEditQuestion") +
          (!!customVehicleField
            ? ` - ${questionLabelByType[questionType]} (${customVehicleField.type})`
            : "")
        }
        cancelButtonText={t("cancel")}
        confirmButtonText={t("save")}
        initialValues={props.question}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="frontendId" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          required
          name="questionType"
          label={t("builder.questionFields.type")}
          hidden={["custom-field-update", "linking"].includes(questionType)}
        >
          {questionType !== "custom-field-update" && (
            <QuestionTypeWithAvatar
              questionType={questionType}
              label={questionLabelByType[questionType]}
              onChangeButtonClick={() =>
                props.onChangeQuestionTypeClick(form.getFieldsValue())
              }
            />
          )}
        </Form.Item>
        {!!customVehicleField && (
          <Form.Item
            name="customVehicleFieldId"
            label={t("builder.questionFields.customVehicleField")}
            required
          >
            <QuestionTypeWithAvatar
              questionType={customVehicleField.type}
              label={customVehicleField.label}
              onChangeButtonClick={() =>
                props.onChangeCustomVehicleFieldClick(form.getFieldsValue())
              }
            />
          </Form.Item>
        )}
        {!!linkType && (
          <Form.Item
            name="linkTypeId"
            label={t("builder.questionFields.linkType")}
            required
          >
            <QuestionTypeWithAvatar
              questionType="linking"
              label={`${linkType.leftName} ↔ ${linkType.rightName}`}
            />
          </Form.Item>
        )}

        <DefaultLanguageRequiredFormItem
          name="label"
          label={t("builder.questionFields.label")}
        >
          <MultiLanguageTextarea />
        </DefaultLanguageRequiredFormItem>
        <Form.Item
          name="description"
          label={t("builder.questionFields.description")}
        >
          <MultiLanguageTextarea />
        </Form.Item>
        <Form.Item
          name="descriptionImage"
          label={t("builder.questionFields.descriptionImage")}
        >
          <SingleImageImmediateUploader uploaderId="descriptionImage" />
        </Form.Item>
        {[
          "text",
          "number",
          "date",
          "date-month",
          "photo-action",
          "linking",
        ].includes(questionType) ||
        (questionType === "custom-field-update" && !!customVehicleField) ? (
          <Form.Item
            name="isRequired"
            valuePropName="isChecked"
            trigger="onToggle"
            style={{
              marginBottom: 0, // only if the next form field is the SwitchInput
            }}
          >
            <SwitchInput
              label={
                questionType === "custom-field-update" &&
                !!customVehicleField &&
                customVehicleField.type === "damages"
                  ? t("builder.questionFields.requireDamagesListConfirmation")
                  : t("builder.questionFields.isRequired")
              }
            />
          </Form.Item>
        ) : null}
        {!!customVehicleField && (
          <>
            {!["photo-action", "damages"].includes(customVehicleField.type) && (
              <Form.Item
                name="isCustomVehicleFieldVerification"
                valuePropName="isChecked"
                trigger="onToggle"
              >
                <SwitchInput
                  label={t(
                    "builder.questionFields.isCustomVehicleFieldVerification"
                  )}
                />
              </Form.Item>
            )}
          </>
        )}

        {["choice", "multi-choice"].includes(questionType) && (
          <DraggableChoicesListFormItem
            getFieldValue={form.getFieldValue}
            type={
              questionType === "multi-choice"
                ? "isRequiredSwitch"
                : "statusSelector"
            }
          />
        )}
      </ModalForm>
    </>
  );
}
