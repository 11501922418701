import { Modal, Progress, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { disableReloadPageConfirmation } from "src/utils";

const automaticReloadTime = 30;

interface PageReloadModalProps {
  title: string;
  paragraph1: string;
  paragraph2?: string;
}

export function PageReloadModal(props: PageReloadModalProps): JSX.Element {
  const { t } = useTranslation("translation");
  const [remainingSeconds, setRemainingSeconds] = useState(automaticReloadTime);
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      setRemainingSeconds((seconds) => --seconds);
    }, 1000);
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [remainingSeconds]);

  useEffect(() => {
    if (intervalIdRef.current && remainingSeconds <= 0) {
      clearInterval(intervalIdRef.current);
      reloadPage();
    }
  }, [remainingSeconds]);

  return (
    <Modal
      open
      title={props.title}
      onOk={() => {
        reloadPage();
      }}
      okText={t("pageReloadModal.reloadNowButtonText")}
      closable={false}
      cancelButtonProps={{
        hidden: true,
      }}
    >
      <Typography.Paragraph>{props.paragraph1}</Typography.Paragraph>
      {props.paragraph2 && (
        <Typography.Paragraph>{props.paragraph2}</Typography.Paragraph>
      )}
      <Typography.Paragraph>
        {t("pageReloadModal.pageWillAutomaticallyReloadInSeconds", {
          remainingSeconds,
        })}
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Progress
          status="normal"
          percent={(100 * remainingSeconds) / (automaticReloadTime + 1)} // +1 in denominator in order to never have 100%
          showInfo={false}
        />
      </Typography.Paragraph>
    </Modal>
  );

  function reloadPage(): void {
    disableReloadPageConfirmation();
    window.location.reload();
  }
}
