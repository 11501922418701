import { ApiError, useCompany } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { PageReloadModal, Spinner } from "src/components";
import { NoConnectionResultPage } from "src/components/NoConnectionResultPage";
import {
  useValueFromQueryParams,
  useServiceWorker,
  useIsTabActive,
} from "src/hooks";
import { attemptLoginByQueryParams } from "src/protocolFiller/utils";
import { useReporting } from "src/reporting/useReporting";
import { ChangeUserServiceWorkerMessage } from "src/types";
import { urls } from "src/urls";
import { api } from "src/utils";

import { authenticationApi } from "./api";
import { useIsUserSessionExpired } from "./hooks";
import { Permission, User } from "./models";

interface AuthenticationContext {
  user: User | null;
  hasPerm(permission: Permission): boolean;
  login(user: User): void;
  logout(): Promise<void>;
  logoutAndRedirectToLandingPage(): Promise<void>;
  isLoggingOut: boolean;
  reloadUser(): Promise<void>;
  reloadUserWithoutLoading(): Promise<void>;
}

export const authenticationContextInitialValues: AuthenticationContext = {
  user: null,
  login(_) {},
  logout() {
    return Promise.resolve();
  },
  logoutAndRedirectToLandingPage() {
    return Promise.resolve();
  },
  isLoggingOut: false,
  reloadUser() {
    return Promise.resolve();
  },
  hasPerm() {
    return false;
  },
  reloadUserWithoutLoading: () => Promise.resolve(),
};

export const authenticationContext = createContext<AuthenticationContext>(
  authenticationContextInitialValues
);

const { Provider } = authenticationContext;

interface Props {
  children: JSX.Element;
}

export function AuthenticationManager({ children }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, setAuthenticatedUser] = useState<User | null>(null);
  const [isUserSessionExpired] = useIsUserSessionExpired(user);
  const isTabActive = useIsTabActive();
  const previousIsTabActive = useRef(isTabActive);
  const company = useCompany();
  const reporting = useReporting();
  const [loadingUserState, setLoadingUserState] = useState<
    "loading" | "loaded" | "error"
  >("loading");
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const driverLoginPinToUse = useValueFromQueryParams("driverLoginPin");
  const { sendServiceWorkerMessage } = useServiceWorker();

  const logout = useCallback(async () => {
    try {
      setIsLoggingOut(true);
      await authenticationApi.logout();

      setAuthenticatedUser(null);
    } catch (e) {
      if (!(e instanceof ApiError)) {
        throw e;
      }
    } finally {
      setIsLoggingOut(false);
      sendServiceWorkerMessage({ type: "LOGOUT" });
    }
  }, [sendServiceWorkerMessage]);

  const login = useCallback(
    async (user: User) => {
      setAuthenticatedUser(user);
      sendServiceWorkerMessage({
        type: "CHANGE_USER",
        canViewProtocolFiller: user.canViewProtocolFiller,
        canViewBackoffice: user.canViewBackoffice,
        forceRecache: true,
      } as ChangeUserServiceWorkerMessage);
    },
    [sendServiceWorkerMessage]
  );

  const logoutAndRedirectToLandingPage = useCallback(async () => {
    try {
      await logout();
      history.push(urls.landingPage());
    } catch (e) {
      throw e;
    }
  }, [history, logout]);

  const hasPerm = useCallback(
    (permission: Permission) => {
      return user ? user.permissions.includes(permission) : false;
    },
    [user]
  );

  const fetchCurrentUser = useCallback(async () => {
    return await authenticationApi.getCurrentUser();
  }, []);

  const fetchAndSetCurrentUserWithoutLoading = useCallback(async () => {
    const user = await attemptLoginByQueryParams(
      company.id,
      fetchCurrentUser,
      driverLoginPinToUse
    );
    setAuthenticatedUser(user);
  }, [company.id, driverLoginPinToUse, fetchCurrentUser]);

  const fetchAndSetCurrentUser = useCallback(async () => {
    setLoadingUserState("loading");
    try {
      await fetchAndSetCurrentUserWithoutLoading();
      setLoadingUserState("loaded");
    } catch (e) {
      setLoadingUserState("error");
    }
  }, [fetchAndSetCurrentUserWithoutLoading]);

  useEffect(() => {
    fetchAndSetCurrentUser();
  }, [fetchAndSetCurrentUser]);

  useEffect(() => {
    if (user && !user.isStaff) {
      reporting.initialize();
      reporting.setCurrentUser(user);
    } else {
      reporting.stop();
      reporting.setCurrentUser(null);
    }

    return () => {
      reporting.stop();
    };
  }, [reporting, user]);

  useEffect(() => {
    if (user && !user.isStaff) {
      sendServiceWorkerMessage({
        type: "CHANGE_USER",
        canViewProtocolFiller: user.canViewProtocolFiller,
        canViewBackoffice: user.canViewBackoffice,
        forceRecache: false,
      } as ChangeUserServiceWorkerMessage);
    }
  }, [user, sendServiceWorkerMessage]);

  useEffect(() => {
    api.setErrorHandler(401, () => {
      setAuthenticatedUser(null);
    });
  }, []);

  useEffect(() => {
    return () => {
      reporting.setCurrentUser(null);
      reporting.stop();
    };
  }, [reporting]);

  useEffect(() => {
    if (isUserSessionExpired) {
      Sentry.captureMessage("User Session Expired", {
        level: Sentry.Severity.Warning,
        extra: {
          userId: user?.id,
        },
      });
    }
  }, [isUserSessionExpired, user?.id]);

  useEffect(() => {
    if (!previousIsTabActive.current && isTabActive) {
      fetchAndSetCurrentUserWithoutLoading();
    }

    previousIsTabActive.current = isTabActive;
  }, [isTabActive, fetchAndSetCurrentUserWithoutLoading]);

  switch (loadingUserState) {
    case "error":
      return (
        <NoConnectionResultPage onRetryClick={() => fetchAndSetCurrentUser()} />
      );
    case "loading":
      return <Spinner text={t("loadingUser")} />;
    case "loaded":
      return (
        <Provider
          value={{
            user,
            login,
            logout,
            logoutAndRedirectToLandingPage,
            isLoggingOut,
            reloadUser: fetchAndSetCurrentUser,
            hasPerm,
            reloadUserWithoutLoading: fetchAndSetCurrentUserWithoutLoading,
          }}
        >
          {isUserSessionExpired && (
            <PageReloadModal
              title={t("errors.sessionExpired.title")}
              paragraph1={t("errors.sessionExpired.description")}
            />
          )}
          {children}
        </Provider>
      );
  }
}

export function useAuthentication(): AuthenticationContext {
  return useContext(authenticationContext);
}
