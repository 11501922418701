import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { User } from "./models";

export function useIsUserSessionExpired(user: User | null): [boolean] {
  const [isUserSessionExpired, setIsUserSessionExpired] = useState(false);

  useEffect(() => {
    if (!user?.sessionExpiresAt) {
      return;
    }

    const expiresAt = dayjs(user.sessionExpiresAt);
    const now = dayjs();

    if (expiresAt.isBefore(now)) {
      setIsUserSessionExpired(true);
    } else {
      const timeout = expiresAt.diff(now);
      const timer = setTimeout(() => setIsUserSessionExpired(true), timeout);

      return () => clearTimeout(timer);
    }
  }, [user]);

  return [isUserSessionExpired];
}
