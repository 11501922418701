import {
  ArrowRightOutlined,
  SmileOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import {
  Alert,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Skeleton,
  Space,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as uuid from "uuid";

import { MultiLanguageTextInput } from "src/company";
import { emptyTranslatedField } from "src/locales";
import { urls } from "src/urls";
import { getHaveFieldsUpdatedCallback } from "src/utils";

import {
  DefaultLanguageRequiredFormItem,
  FormItemValue,
  LockedFeatureIcon,
  SelectAddLink,
} from "../../../components";
import {
  useTemplateTagsRequirementsByVehicleType,
  useVehicleGroups,
  useVehicleTypes,
} from "../../../hooks";
import { useTemplateTags } from "../../../hooks/useTemplateTags";
import { useUserRoleOptions } from "../../../hooks/useUserRoleOptions";
import { ListTemplate, SignaturePage, SignatureType } from "../../../models";
import { useTemplateContext } from "../TemplateEditorView";
import { getTemplateTagsRequiredForTemplateExplanation } from "../utils";
import { ComparePhotosQualityModal } from "./ComparePhotosQualityModal";
import { PageComponent } from "./PageComponent";
import { PageListWrapper, PagesList } from "./PagesList";
import { SignaturePageComponent } from "./SignaturePageComponent";

export function TemplateEditor() {
  const company = useCompany();
  const { t } = useTranslation("backoffice");
  const [templateTagsRequirementsByVehicleType] =
    useTemplateTagsRequirementsByVehicleType();
  const { isLoadingVehicleGroups, vehicleGroupOptions } = useVehicleGroups();
  const { isLoadingTemplateTags, templateTagOptions } = useTemplateTags();
  const { isLoadingVehicleTypes, vehicleTypeOptions } = useVehicleTypes();
  const { isLoadingUserRoles, userRoleOptions } = useUserRoleOptions();
  const { createSignaturePage, removeSignaturePage } = useTemplateContext();
  const [isComparePhotosQualityModalOpen, setIsComparePhotosQualityModalOpen] =
    useState(false);

  return isLoadingVehicleTypes ? (
    <Skeleton />
  ) : (
    <>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="frontendId" hidden>
        <Input />
      </Form.Item>
      <DefaultLanguageRequiredFormItem
        maxLength={256}
        name="label"
        label={t("builder.templateLabel")}
        style={{ maxWidth: 500 }}
      >
        <MultiLanguageTextInput />
      </DefaultLanguageRequiredFormItem>
      <Form.Item
        name="vehicleType"
        label={t("builder.vehicleTypes")}
        rules={[{ required: true }]}
        style={{ maxWidth: 500 }}
      >
        <Select
          mode="multiple"
          optionFilterProp="label"
          options={vehicleTypeOptions}
          loading={isLoadingVehicleTypes}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate={getHaveFieldsUpdatedCallback(
          "vehicleType",
          "templateTags"
        )}
        noStyle
      >
        {({ getFieldValue }) => {
          const vehicleTypeIds: string[] = getFieldValue("vehicleType") || [];
          const templateTags: string[] = getFieldValue("templateTags") || [];

          if (templateTags.length) {
            return;
          }
          const vehicleTypes: ListTemplate["vehicleTypes"] = vehicleTypeOptions
            .filter((vehicleTypeOption) =>
              vehicleTypeIds.includes(vehicleTypeOption.value)
            )
            .map((option) => ({
              id: option.value,
              label: option.label,
            }));

          const templateTagsRequiredForTemplateExplanation =
            getTemplateTagsRequiredForTemplateExplanation(
              t,
              templateTagsRequirementsByVehicleType,
              vehicleTypes
            );
          return templateTagsRequiredForTemplateExplanation ? (
            <Alert
              style={{ marginBottom: 10 }}
              showIcon
              type="warning"
              message={
                templateTagsRequiredForTemplateExplanation.tagsAreRequiredText
              }
              description={
                templateTagsRequiredForTemplateExplanation.templateTagsRequiredForTemplateExplanation
              }
            />
          ) : null;
        }}
      </Form.Item>
      <Form.Item
        name="templateTags"
        label={t("templatesListView.templateTags")}
        tooltip={t("builder.templateTagsHelpTooltip")}
        style={{ maxWidth: 500 }}
      >
        <Select
          mode="multiple"
          optionFilterProp="label"
          options={templateTagOptions}
          loading={isLoadingTemplateTags}
          dropdownRender={(menu) => (
            <SelectAddLink addUrl={urls.backOffice.settings.templateTags()}>
              {menu}
            </SelectAddLink>
          )}
        />
      </Form.Item>
      <Form.Item
        name="vehicleGroups"
        label={t("vehicleGroups")}
        tooltip={t("builder.vehicleGroupsHelpTooltip")}
        style={{ maxWidth: 500 }}
      >
        <Select
          mode="multiple"
          optionFilterProp="label"
          loading={isLoadingVehicleGroups}
          options={vehicleGroupOptions}
        />
      </Form.Item>
      <Form.Item
        name="userRoles"
        label={t("builder.userRoles")}
        tooltip={t("builder.userRolesHelpTooltip")}
        style={{ maxWidth: 500 }}
      >
        <Select
          mode="multiple"
          optionFilterProp="label"
          loading={isLoadingUserRoles}
          options={userRoleOptions}
          placeholder={t("builder.userRolesPlaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="imageQuality"
        label={t("builder.imageQuality.fieldName")}
        tooltip={t("builder.imageQuality.helpText")}
        rules={[{ required: true }]}
      >
        <FormItemValue<string>>
          {({ value, onChange }) => (
            <Space size="small">
              <Radio.Group
                value={value}
                buttonStyle="outline"
                optionType="button"
                onChange={(e) => {
                  const targetValue = e.target.value;

                  if (
                    !company.allowStoringHighQualityImages &&
                    targetValue === "highQuality"
                  ) {
                    Modal.info({
                      title: t("builder.imageQuality.blockedFeature"),
                    });
                    return;
                  }

                  onChange?.(e.target.value);
                }}
                options={[
                  {
                    label: (
                      <Space>
                        <SmileOutlined />
                        {t("builder.imageQuality.options.standard")}
                      </Space>
                    ),
                    value: "standard",
                  },
                  {
                    label: (
                      <Space>
                        <StarOutlined />
                        {t("builder.imageQuality.options.highQuality")}
                        {!company.allowStoringHighQualityImages && (
                          <LockedFeatureIcon />
                        )}
                      </Space>
                    ),
                    value: "highQuality",
                  },
                ]}
              />
              <Button
                type="link"
                onClick={() => setIsComparePhotosQualityModalOpen(true)}
              >
                {t("builder.imageQuality.seeComparison")}
                <ArrowRightOutlined rotate={-45} />
              </Button>
            </Space>
          )}
        </FormItemValue>
      </Form.Item>
      <Form.Item
        name="pages"
        label={t("builder.pages")}
        tooltip={t("builder.pageExplanation")}
        rules={[
          {
            min: 1,
            type: "array",
            message: t("builder.youNeedAtLeastOnePage"),
          },
        ]}
        valuePropName="pages"
      >
        <PagesList
          addPageTitle={t("builder.addPage")}
          pageType="pages"
          renderPage={(
            page,
            allPages,
            index,
            {
              removePage,
              saveQuestion,
              addQuestions,
              removeQuestion,
              insertPage,
              insertQuestion,
              savePageTitle,
            }
          ) => (
            <PageComponent
              page={page}
              key={page.frontendId}
              index={index}
              removePage={removePage}
              savePageTitle={savePageTitle}
              allPages={allPages}
              saveQuestion={saveQuestion}
              addQuestions={addQuestions}
              removeQuestion={removeQuestion}
              insertPage={insertPage}
              insertQuestion={insertQuestion}
            />
          )}
        />
      </Form.Item>
      <Form.Item
        label={t("builder.signatures")}
        shouldUpdate={getHaveFieldsUpdatedCallback("signaturePages")}
      >
        {({ getFieldValue }) => {
          const fields: SignaturePage[] = getFieldValue("signaturePages") || [];
          return (
            <PageListWrapper
              onCreate={() => {
                createSignaturePage({
                  frontendId: uuid.v4(),
                  id: null,
                  isRequired: true,
                  signerRoles: [],
                  label: emptyTranslatedField,
                  type: SignatureType.SIGNATURE_ONLY,
                });
              }}
              addPageTitle={t("builder.addSignaturePage")}
              pageType="signaturePages"
            >
              {fields.length === 0 ? (
                <Empty description={false} />
              ) : (
                fields.map((field, index) => (
                  <SignaturePageComponent
                    key={field.frontendId}
                    fieldPrefix={index}
                    index={index}
                    removePage={removeSignaturePage}
                    pageFrontendId={field.frontendId}
                    userRoleOptions={userRoleOptions}
                    isLoadingUserRoleOptions={isLoadingUserRoles}
                  />
                ))
              )}
            </PageListWrapper>
          );
        }}
      </Form.Item>
      {isComparePhotosQualityModalOpen && (
        <ComparePhotosQualityModal
          onCancel={() => setIsComparePhotosQualityModalOpen(false)}
        />
      )}
    </>
  );
}
