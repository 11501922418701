import { StepBackwardOutlined, StepForwardOutlined } from "@ant-design/icons";
import {
  allProtocolFillerLanguages,
  allBackofficeLanguages,
} from "@inspecto/common";
import { Badge, Button, Grid, Select, Space, Switch } from "antd";
import {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { Icon } from "src/components";
import { languageContext } from "src/locales";

import { Provider } from "./context";
import { DevToolbarContext } from "./index";
import {
  FixedToolbar,
  ToolbarToggler,
  Item,
  TopLeftDevInformation,
  HorizontalDivider,
  VerticalDivider,
} from "./styles";

type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";

function useSyncedLocalStorageState(propName: string): [boolean, () => void] {
  const [stateValue, setStateValue] = useState(propName in localStorage);

  useEffect(() => {
    if (stateValue) {
      localStorage[propName] = true;
    } else {
      delete localStorage[propName];
    }
  }, [propName, stateValue]);

  return [stateValue, () => setStateValue((value) => !value)];
}

export function DevToolbar({
  enabled,
  children,
}: {
  children: ReactNode;
  enabled: boolean;
}): JSX.Element {
  const { dispatch: languageDispatch, value: currentLanguage } =
    useContext(languageContext);

  const location = useLocation();
  const breakpoint = Grid.useBreakpoint();
  const [isToolbarVisible, toggleToolbarVisible] =
    useSyncedLocalStorageState("devToolbarVisible");
  const [shouldShowLocation, toggleShouldShowLocation] =
    useSyncedLocalStorageState("showLocation");
  const [shouldShowLocalStorage, toggleShouldShowLocalStorage] =
    useSyncedLocalStorageState("localStorage");
  const [shouldDelayRequests, toggleShouldDelayRequests] =
    useSyncedLocalStorageState("delayRequests");

  const currentBreakpoint = getCurrentBreakpoint();
  const [localStorageSize, setLocalStorageSize] = useState(0);
  const [locationInformation, setLocationInformation] = useState<
    Parameters<DevToolbarContext["setDevToolbarLocationInformation"]>[0]
  >({
    currentPosition: null,
    bestPosition: null,
  });

  const [languageSet, setLanguageSet] = useState<
    "protocolFiller" | "backoffice"
  >("protocolFiller");

  const isInProtocolFiller = useMemo(() => {
    return location.pathname.includes("protocol-filler");
  }, [location]);

  useEffect(() => {
    if (isInProtocolFiller) {
      setLanguageSet("protocolFiller");
    } else {
      setLanguageSet("backoffice");
    }
  }, [isInProtocolFiller]);

  const currentLanguages =
    languageSet === "protocolFiller"
      ? allProtocolFillerLanguages
      : allBackofficeLanguages;

  const getNextLanguage = () => {
    // @ts-ignore
    const currentIndex = currentLanguages.indexOf(currentLanguage.language);
    const nextIndex = (currentIndex + 1) % currentLanguages.length;
    return currentLanguages[nextIndex] || "en";
  };

  const getPreviousLanguage = () => {
    // @ts-ignore
    const currentIndex = currentLanguages.indexOf(currentLanguage.language);
    const previousIndex =
      (currentIndex - 1 + currentLanguages.length) % currentLanguages.length;
    return currentLanguages[previousIndex] || "en";
  };

  const items: JSX.Element[] = [
    <Badge count={currentBreakpoint} style={{ backgroundColor: "#009688" }} />,
    <Space>
      <Switch
        checked={shouldDelayRequests}
        onChange={toggleShouldDelayRequests}
      />
      <span>Delay{breakpoint.sm && " Requests"}</span>
    </Space>,
    <Space>
      <Switch
        checked={shouldShowLocalStorage}
        onChange={toggleShouldShowLocalStorage}
      />
      <span>LS</span>
    </Space>,
    <Space>
      <Switch
        checked={shouldShowLocation}
        onChange={toggleShouldShowLocation}
      />
      <span>Loc</span>
    </Space>,
    <Space size="middle">
      {breakpoint.sm && <span>Language:</span>}
      <Space size="small">
        <Button
          size="small"
          onClick={() =>
            languageDispatch({
              type: "setLanguage",
              payload: { language: getPreviousLanguage() },
            })
          }
        >
          <StepBackwardOutlined />
        </Button>
        <Select
          placement="topLeft"
          size="small"
          value={languageSet}
          onChange={(value) => setLanguageSet(value)}
        >
          <Select.Option value="protocolFiller">ProtocolFiller</Select.Option>
          <Select.Option value="backoffice">BackOffice</Select.Option>
        </Select>
        <Select
          placement="topLeft"
          size="small"
          value={currentLanguage.language}
          onChange={(language) =>
            languageDispatch({
              type: "setLanguage",
              payload: { language },
            })
          }
        >
          {currentLanguages.map((language) => (
            <Select.Option value={language} key={language}>
              {language.toUpperCase()}
            </Select.Option>
          ))}
          <Select.Option value="keys-only-invalid-language">
            Keys Only
          </Select.Option>
        </Select>
        <Button
          size="small"
          onClick={() =>
            languageDispatch({
              type: "setLanguage",
              payload: { language: getNextLanguage() },
            })
          }
        >
          <StepForwardOutlined />
        </Button>
      </Space>
    </Space>,
  ];

  const isHorizontalStack = breakpoint.lg;

  return (
    <Provider
      value={{
        setDevToolbarLocationInformation: setLocationInformation,
        setDevToolbarLocalStorageSize: setLocalStorageSize,
        isDevToolbarEnabled: enabled,
      }}
    >
      {children}
      {enabled && (
        <>
          {isToolbarVisible && (
            <FixedToolbar>
              <Space
                style={{ width: "100%" }}
                size={breakpoint.md ? "middle" : "small"}
                direction={isHorizontalStack ? "horizontal" : "vertical"}
              >
                {items.map((item, index) => (
                  <Fragment key={index}>
                    <Item horizontal={isHorizontalStack}>{item}</Item>
                    {index < items.length - 1 &&
                      (isHorizontalStack ? (
                        <VerticalDivider />
                      ) : (
                        <HorizontalDivider />
                      ))}
                  </Fragment>
                ))}
              </Space>
            </FixedToolbar>
          )}
          <ToolbarToggler>
            <Button
              ghost={isToolbarVisible}
              onClick={toggleToolbarVisible}
              shape="circle"
              icon={
                <Icon
                  icon={isToolbarVisible ? "eyeSlashRegular" : "eyeRegular"}
                />
              }
              size="small"
            />
          </ToolbarToggler>
          <TopLeftDevInformation>
            {shouldShowLocalStorage && (
              <div>LS: {localStorageSize.toFixed(2)} KB</div>
            )}
            {shouldShowLocation && (
              <>
                <div>Location:</div>
                <div>
                  Current:{" "}
                  {locationInformation.currentPosition && (
                    <>
                      {locationInformation.currentPosition.longitude},
                      {locationInformation.currentPosition.latitude},
                      {locationInformation.currentPosition.accuracy}
                    </>
                  )}
                </div>

                <div>
                  Best:{" "}
                  {locationInformation.bestPosition && (
                    <>
                      {locationInformation.bestPosition.longitude},
                      {locationInformation.bestPosition.latitude},
                      {locationInformation.bestPosition.accuracy}
                    </>
                  )}
                </div>
              </>
            )}
          </TopLeftDevInformation>
        </>
      )}
    </Provider>
  );

  function getCurrentBreakpoint(): string {
    const fromLargestToSmallest = ["XXL", "XL", "LG", "MD", "SM", "XS"];
    const toReturn = fromLargestToSmallest.find((breakpointUppercase) => {
      return breakpoint[breakpointUppercase.toLowerCase() as Breakpoint];
    });
    return toReturn || "-";
  }
}
